// BCCFA colour definitions

$primary: #1f2b67;
$secondary: #007a99;
$tertiary: #0d705c;
$blue: $primary;
$teal: $secondary;
$green: $tertiary;
$dark-pink:#6c276e;
$purple: #2e0d70;
$light-grey: #f6f6f6;
$grey: #e4e4e4;
$dark-grey: #444;
$link-color: $secondary;
$accordion-icon-color: $primary;
$success: $secondary;
$danger: #600;
$info: #36b;
$warning: #998000;
$black: #000;
$white: #fff;


$headings-font-family: 'Open Sans', sans-serif;
$font-family-base: 'Open Sans', sans-serif;

// set up the variables for colour options
$theme-colors: (
        "primary": $primary,
        "secondary": $secondary,
        "tertiary": $tertiary,
        "success": $success,
        "danger": $danger,
        "info": $info,
        "warning": $warning,
        "light-grey": $light-grey,
        "grey": $grey,
);

$custom-colors: (
        "blue": $blue,
        "dark-grey": $dark-grey,
        "green": $green,
        "dark-pink": $dark-pink,
        "light-grey": $light-grey,
        "purple" : $purple,
        "teal": $teal,
        "white": $white,
        
);

$container-max-widths: (
        sm:1600px,
        md:1600px,
        lg:1600px,
        xl: 1600px,
        xxl: 1600px
);

$border-wrapper-max-widths: (
        "container-wide": 1600px,
        "container": 1600px
) !default;

$border-wrapper-max-width: map-get($container-max-widths, 'xxl') !default;

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1400px,
        xxxl:1600px,
        xxxxl:1920px,
        xxxxxl:2500px
);

$spacer: 1rem;
$grid-gutter-width: 3rem;
$gutters: (
        0: 0,
        1: $spacer * .25,
        2: $spacer * .5,
        3: $spacer,
        4: $spacer * 1.5,
        5: $spacer * 3,
        6: $spacer * 5,
        7: $spacer * 6
);

$spacers: (
        0: 0,
        1: $spacer * .25,
        2: $spacer * .5,
        3: $spacer,
        4: $spacer * 1.5,
        5: $spacer * 3,
        6: $spacer * 5,
        7: $spacer * 8
);

$position-values: (
        0: 0,
        50: 50%,
        100: 100%
);

$accordion-icon-color: $primary;
$accordion-button-active-color: $primary;

$border-radius-lg: .6rem;

$btn-hover-bg-shade-amount: 30%;
$btn-active-bg-shade-amount: 30%;
$btn-font-size:1.1rem;

$nav-tabs-link-active-bg: $light-grey;
$navbar-nav-link-padding-x:1em;
$nav-link-font-size:1rem;
$navbar-light-color:$white;

$pagination-active-bg: $secondary;
$progress-bg: $white;

$box-shadow-sm: 0 .1rem .45rem rgba($black, .095);

$badge-border-radius: .5em;


// offcanvas (currently used for mobile nav slide-in) variables
$offcanvas-bg-color: $secondary;
$offcanvas-color: $light-grey;

// carousel formatting (used for testimonial slider)
// arrows
$carousel-control-color: $primary;
$carousel-control-width: 5%;

// indicators at the bottom
$carousel-indicator-width: 10px;
$carousel-indicator-height: 10px;
$carousel-indicator-hit-area-height: 10px;
$carousel-indicator-spacer: 3px;
$carousel-indicator-opacity: .5;
$carousel-indicator-active-bg: $primary;
$carousel-indicator-active-opacity: 1;
$carousel-indicator-transition: opacity .6s ease;

$theme-colors: map-merge($theme-colors, $custom-colors);
