@import "customizable_variables";
@import "variables";
@import 'bootstrap/scss/bootstrap';
@import "container_padding";


.ccm-context-theme {
  @each $color, $value in $theme-colors {
    --#{$variable-prefix}#{$color}: #{$value};
  }
}